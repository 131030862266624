import * as React from 'react';
import { Colors } from '@kvika/audur-theme';

import { StyledButton, StyledTitle } from './styles/TabStyles';
import { Variant, VariantUnion } from './Constants';

const getVariantAsEnum = (variant: VariantUnion): Variant => {
  switch (variant) {
    case 'darkBackground':
      return Variant.DarkBackground;
    case 'grayBackground':
      return Variant.GrayBackground;
    case 'lightBackground':
      return Variant.LightBackground;
    default:
      return Variant.DarkBackground;
  }
};

type Props = {
  title: string;
  value: number;
  onClick: (value: number) => void;
  selected: boolean;
  selectedColor: Colors | string;
  testId?: string;
  variant: VariantUnion;
};

const Tab = ({ title, value, onClick, selected, selectedColor, testId, variant }: Props): JSX.Element => {
  return (
    <StyledButton
      onClick={() => onClick(value)}
      selected={selected}
      selectedColor={selectedColor}
      data-testid={`${testId}-${value}`}
      variant={getVariantAsEnum(variant)}
      tabIndex={0}
    >
      <StyledTitle selected={selected} selectedColor={selectedColor} data-testid={`${testId}-title-${value}`}>
        {title}
      </StyledTitle>
    </StyledButton>
  );
};

export default Tab;
