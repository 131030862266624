import { Colors } from '@kvika/audur-theme';
import { prettifySSN } from '@kvika/string-utils';
import { useEffect, useRef, useState } from 'react';
import { GeneralStrings } from 'utils/strings';

import Tab, { Tabs } from 'components/Tabs';
import AuthForm, { InputTypes } from './AuthForm';
import TabPanel from './TabPanel';

type Props = {
  onTabChange: () => void;
  phoneNumber: string;
  onChangePhoneNumber: (phoneNumber: string) => void;
  ssn: string;
  onChangeSSN: (ssn: string) => void;
  isLoading: boolean;
  isLoggingInWithPhoneNumber: boolean;
  setIsLoggingInWithPhoneNumber: (isLoggingInWithPhoneNumber: boolean) => void;
  onLoginStart(): void;
  verificationCode?: string;
};

enum AuthTab {
  PhoneTab = 0,
  SSNTab = 1,
}

const AuthMethodTabs = ({
  onTabChange,
  phoneNumber,
  onChangePhoneNumber,
  ssn,
  onChangeSSN,
  isLoading,
  isLoggingInWithPhoneNumber,
  setIsLoggingInWithPhoneNumber,
  onLoginStart,
  verificationCode,
}: Props) => {
  const [tabIndex, setTabIndex] = useState(AuthTab.PhoneTab);

  const handleTabChange = () => {
    setTabIndex(isLoggingInWithPhoneNumber ? AuthTab.SSNTab : AuthTab.PhoneTab);
    onTabChange();
  };

  useEffect(() => {
    setIsLoggingInWithPhoneNumber(tabIndex === AuthTab.PhoneTab);
  }, [tabIndex, setIsLoggingInWithPhoneNumber]);

  const inputPhonetRef = useRef<HTMLInputElement>(null);
  const inputSSNRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isLoggingInWithPhoneNumber && inputPhonetRef.current) {
      inputPhonetRef.current.focus();
    } else if (inputSSNRef.current) {
      inputSSNRef.current.focus();
    }
  }, [isLoggingInWithPhoneNumber]);

  return (
    <div style={{ color: Colors.Beige, marginTop: 32 }}>
      <Tabs>
        <Tab
          title={GeneralStrings.ElectronicId}
          value={AuthTab.PhoneTab}
          onClick={() => !isLoading && handleTabChange()}
          selected={tabIndex === AuthTab.PhoneTab}
          selectedColor={Colors.Beige}
          variant="darkBackground"
        />
        <Tab
          title={GeneralStrings.ElectronicSim}
          value={AuthTab.SSNTab}
          onClick={() => !isLoading && handleTabChange()}
          selected={tabIndex === AuthTab.SSNTab}
          selectedColor={Colors.Beige}
          variant="darkBackground"
        />
      </Tabs>
      <TabPanel value={tabIndex} index={AuthTab.PhoneTab}>
        <AuthForm
          label={GeneralStrings.TelephoneNumber}
          onChange={onChangePhoneNumber}
          isLoading={isLoading}
          value={phoneNumber}
          inputType={InputTypes.Phone}
          ref={inputPhonetRef}
          onLoginStart={onLoginStart}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={AuthTab.SSNTab}>
        <AuthForm
          label={GeneralStrings.SocialSecurityNumber}
          onChange={onChangeSSN}
          isLoading={isLoading}
          value={prettifySSN(ssn)}
          inputType={InputTypes.SSN}
          verificationCode={verificationCode}
          ref={inputSSNRef}
          onLoginStart={onLoginStart}
        />
      </TabPanel>
    </div>
  );
};

export default AuthMethodTabs;
