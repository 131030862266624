import { Colors } from '@kvika/audur-theme';
import { Grid } from '@kvika/theme';
import styled, { css } from 'styled-components';
import { Variant } from '../Constants';

type ButtonProps = {
  selected: boolean;
  selectedColor: Colors | string;
  variant: Variant;
};

export const VARIANT_STYLES = {
  [Variant.DarkBackground]: css`
    color: ${Colors.LightGray};
    border-bottom: ${Grid['4px']} solid ${Colors.CardGray};
  `,
  [Variant.GrayBackground]: css`
    color: ${Colors.LightGray};
    border-bottom: ${Grid['4px']} solid ${Colors.DarkGray};
  `,
  [Variant.LightBackground]: css`
    color: ${Colors.BeigeGray};
    border-bottom: ${Grid['4px']} solid ${Colors.BeigeGray};
  `,
};

export const FOCUS_STYLES = {
  [Variant.DarkBackground]: css`
    background-color: ${Colors.CardGray};
  `,
  [Variant.GrayBackground]: css`
    background-color: ${Colors.DarkGray};
  `,
  [Variant.LightBackground]: css`
    color: ${Colors.Beige};
    background-color: ${Colors.BeigeGray};
  `,
};

const getVariantStyles = ({ variant }: ButtonProps) => {
  return css`
    ${VARIANT_STYLES[variant]}
  `;
};

const getSelectedStyles = ({ selected, selectedColor }: ButtonProps) => {
  if (selected) {
    return css`
      color: ${selectedColor};
      border-bottom: ${Grid['4px']} solid ${selectedColor};
    `;
  }
  return null;
};

const getHoverStyles = ({ variant }: ButtonProps) => {
  return css`
    :focus-visible {
      ${FOCUS_STYLES[variant]}
    }
    :focus:not(:focus-visible) {
      background-color: initial;
      &:hover {
        ${FOCUS_STYLES[variant]}
      }
    }
    :hover {
      ${FOCUS_STYLES[variant]}
    }
  `;
};

export const StyledButton = styled.button<ButtonProps>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  ${getVariantStyles}
  ${getSelectedStyles}
  ${getHoverStyles}
`;

type TitleProps = {
  selected: boolean;
  selectedColor: Colors | string;
};

export const StyledTitle = styled.span<TitleProps>`
  font-weight: bold;
`;
