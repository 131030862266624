import * as React from 'react';
import { StyledDiv } from './styles/TabsStyles';

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

const Tabs = ({ children }: Props): JSX.Element => {
  return <StyledDiv>{children}</StyledDiv>;
};

export default Tabs;
